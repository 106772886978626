import React from 'react'
import { useNavigate } from 'react-router-dom'
import useLocalStorage from './useLocalStorage'
import Article from './Article'
import Tag from './Tag'

const App = () => {

  const urlParams = new URLSearchParams(document.location.search)

  const [files, setFiles] = useLocalStorage('files', [])
  const [hash, setHash] = useLocalStorage('hash', '')
  const [darkMode, setDarkMode] = useLocalStorage('darkMode', false)

  const [tags, setTags] = React.useState(urlParams.getAll('tag'))
  const [start, setStart] = React.useState(0)
  const [page, setPage] = React.useState(1)
  const [search, setSearch] = React.useState('')
  const [tagsList, setTagsList] = React.useState([])

  const perPage = React.useRef(10)

  const navigate = useNavigate()

  React.useEffect(() => {
    const newParams = new URLSearchParams()
    tags.forEach(tag => {
      newParams.append('tags', tag)
    })
    navigate('?' + newParams.toString())
  }, [tags, navigate])

  React.useEffect(() => {
    const query = hash === '' ? '' : '?' + new URLSearchParams({
      hash: hash,
    })
    fetch(process.env.REACT_APP_API_URL + query)
      .then(response => response.json())
      .then(response => {
        if (response !== true) {
          setFiles(JSON.parse(response.data))
          setHash(response.hash)
        }
      })
      .catch(error => {
        console.log(error)
      })
  }, [hash, setFiles, setHash, setTagsList])

  React.useEffect(() => {
    const allTags = []
    files.forEach(file => {
      file.tags.forEach(tag => {
        allTags.push(tag)
      })
    })
    setTagsList([...new Set(allTags)].sort())
  }, [files])

  const setTag = (tag) => {
    setStart(0)
    setPage(1)
    setTags(prev => {
      if (prev.indexOf(tag) > -1) {
        prev.splice(prev.indexOf(tag), 1)
        return [...prev]
      } else {
        return [
          ...prev,
          tag
        ]
      }
    })
  }

  const filterFilesCount = () => {
    return files.filter(file => {
      if (tags.length === 0) {
        return true
      }
      return file.tags.some(t => tags.includes(t))
    }).filter(file => {
      if (search === '') {
        return true
      }
      return file.title.toLowerCase().indexOf(search.toLowerCase()) > -1 || file.excerpt.toLowerCase().indexOf(search.toLowerCase()) > -1
    }).length
  }

  return (
    <div className={`${darkMode ? 'dark' : ''}`}>
      <div className='min-h-screen overflow-auto bg-zinc-100 dark:bg-zinc-900 wallpaper'>
        <div className='container max-w-screen-lg p-4 mx-auto shadow bg-white/50 dark:bg-zinc-600/50 sm:p-8'>
          <div className='flex flex-col items-start justify-between mb-4 sm:items-center sm:flex-row sm:mb-8'>
            <div className='flex flex-row items-center mb-2 sm:mb-0'>
              <div onClick={e => setDarkMode(prev => !prev)} className={`shrink-0 w-20 h-20 py-2 hover:cursor-pointer mx-auto mr-4 ${darkMode ? 'bg-zinc-800 hover:bg-zinc-900' : 'bg-white, hover:bg-zinc-100'} rounded-full shadow`}>
                <img src={`./${darkMode ? 'Iron-Lamp-Logo-Dark.png' : 'Iron-Lamp-Logo.png'}`} alt='Iron Lamp Logo' className='h-16 mx-auto' />
              </div>
              <div className='flex flex-col'>
                <div className='text-2xl font-bold sm:text-5xl slab dark:text-zinc-50'>Iron Lamp News</div>
                <div className='text-xs italic tracking-wide sm:text-xl slab dark:text-zinc-50'>Igne Natura Renovatur Integra</div>
              </div>
            </div>
            <div className='flex flex-row items-center w-full mt-2 sm:w-auto sm:mt-0'>
              <input value={search} onChange={e => { setSearch(e.target.value); setStart(0); setPage(1) }} placeholder='Search...' className='w-full p-2 text-base border-0 shadow outline-none sm:text-xl sm:w-64 focus:ring-1 focus:ring-zinc-800' />
            </div>
          </div>
          {
            files.filter(file => {
              if (tags.length === 0) {
                return true
              }
              return file.tags.some(t => tags.includes(t))
            }).filter(file => {
              if (search === '') {
                return true
              }
              return file.title.toLowerCase().indexOf(search.toLowerCase()) > -1 || file.excerpt.toLowerCase().indexOf(search.toLowerCase()) > -1
            }).slice(start, page * perPage.current).map(file => {
              return (
                <Article key={file.url} file={file} setTag={setTag} tags={tags} />
              )
            })
          }
          <button onClick={e => setPage(prev => prev + 1)} className={`${filterFilesCount() > page * perPage.current ? 'block' : 'hidden'} px-8 py-2 mx-auto text-xl bg-yellow-300 shadow slab hover:bg-yellow-200 text-zinc-800`}>Load More</button>
          <div className='mt-8 text-center'>
            {
              tagsList.map(tag => {
                return (<Tag key={tag} tag={tag} tags={tags} setTag={setTag} />)
              })
            }
          </div>
          <div className='mt-8 text-sm italic font-bold text-center text-white slab'>When asked why he went about with a lamp in broad daylight, Diogenes confessed, "I am looking for a honest man."</div>
        </div>
      </div>
    </div>
  )
}
export default App