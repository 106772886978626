import Tag from './Tag'

const Article = ({ file, tags, setTag }) => {

    return (
        < div className='flex flex-col justify-between gap-2 mb-8 sm:gap-8 sm:flex-row' >
            <div className='shrink-0 grow-0'>
                <a href={file.url} target='_blank' rel='noreferrer'><img src={`https://ironlamp.news/resources/images/posts/${file.image}`} alt={file.title} className='w-full shadow sm:w-80 hover:brightness-75' /></a>
            </div>
            <div className='grow'>
                <div className='mb-2 text-lg font-bold leading-tight sm:text-2xl dark:text-zinc-50 slab'><a href={file.url} target='_blank' rel='noreferrer' className='hover:underline'>{file.title}</a></div>
                <div className='text-base sm:text-xl dark:text-zinc-50'>{file.excerpt}</div>
                <div className='mt-2'>
                    {
                        file.tags.map(tag => {
                            return (<Tag key={tag} tag={tag} tags={tags} setTag={setTag} />)
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default Article